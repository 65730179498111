import React from 'react';
import bImg from '../../images/blog/shape.png';
import bImg2 from '../../images/blog/shape2.png';
import './blog.css';
const BlogSection = () => {
  return (
    <div className='mainC'>
      <div class='card-container1'>
        <div class='cardOne'>
          <div class='front1'>
            <div class='front-content1'>
              <div class='left1'>
                <span class='bank1'>MoneyTapCard</span>
                <div class='name1'>
                  <span>Shubham</span>
                  <span>Kumar</span>
                </div>
                <h1 style={{ color: 'white' }}>VISA</h1>
              </div>
              <div class='right1'>
                <span id='brand1'>Card</span>
              </div>
              <div class='box1'></div>
            </div>
          </div>
          <div class='back1'>
            <div class='back-content1'>
              <div class='left1'>
                <span class='bank1'>MoneyTapCard</span>
                <div class='name1'>
                  <span>Abhishek</span>
                  <span>Kumar</span>
                </div>
                <h1 style={{ color: 'white' }}>VISA</h1>
              </div>
              <div class='right1'>
                <span id='brand1'>Card</span>
              </div>
              <div class='box1'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
