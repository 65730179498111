import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import { clearLocalData } from '../../utils/CoustomStorage';

const Header5 = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const Logout = () => {
    clearLocalData();
    window.location.reload('/');
  };
  return (
    <header id='header' className={props.topbarNone}>
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className='navigation navbar navbar-expand-lg navbar-light'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-6 col-6'>
                <div className='navbar-header'>
                  <Link onClick={ClickHandler} className='navbar-brand' to='/home'>
                    <img src={props.Logo} alt='' />
                  </Link>
                </div>
              </div>
              <div className='col-lg-6 col-md-1 col-1'>
                <div id='navbar' className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>
                  <ul className='nav navbar-nav mb-2 mb-lg-0'>
                    <li>
                      <Link onClick={ClickHandler} to='/admin-dashboard'>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to='/admin-dashboard-setting'>
                        Setting
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 col-md-2 col-2'>
                <div className='header-right'>
                  <div className='close-form'>
                    <Link onClick={Logout} className='theme-btn'>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header5;
