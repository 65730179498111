import simg from '../images/service/1.png';
import simg2 from '../images/service/2.png';
import simg3 from '../images/service/3.png';
import simg4 from '../images/service/4.png';

import sImgS1 from '../images/service-single/img-1.jpg';
import sImgS2 from '../images/service-single/img-2.jpg';
import sImgS3 from '../images/service-single/img-3.jpg';
import sImgS4 from '../images/service-single/img-4.jpg';

import sSingleimg1 from '../images/service-single/2.jpg';
import sSingleimg2 from '../images/service-single/3.jpg';

const Services = [
  {
    Id: '1',
    sImg: simg,
    sImgS: sImgS1,
    sTitle: 'Silver Credit Card',
    description:
      'Basic benefits, moderate rewards. Perfect for building credit or occasional use, with standard perks included.Basic benefits, moderate rewards. Perfect for building credit or occasional use, with standard perks included.',
    des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    icon: 'flaticon-advisor',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: '2',
    sImg: simg2,
    sImgS: sImgS2,
    sTitle: 'Gold Credit Card',
    description:
      'Unlock exclusive perks, luxury rewards, VIP services, travel benefits, and enhanced security for the discerning individual.',
    des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    icon: 'flaticon-team',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: '3',
    sImg: simg3,
    sImgS: sImgS3,
    sTitle: 'Diamond Credit Card',
    description:
      'Elevate your spending with elite perks, exclusive rewards, and luxury benefits. Experience unparalleled privileges. Apply now for prestige with MoneyTapCard..',
    des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    icon: 'flaticon-taxes',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: '4',
    sImg: simg4,
    sImgS: sImgS4,
    sTitle: 'Platinum Credit Card',
    description:
      'Elevate your financial experience today with elite perks, premium rewards, exclusive benefits, and superior services tailored for discerning customers.',
    des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    icon: 'flaticon-tax',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
];

export default Services;
