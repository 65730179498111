// SKIP_PREFLIGHT_CHECK=true
module.exports = {
  development: {
    // site_api: "http://localhost:9000",
    site_api: 'https://api2.moneytapcard.com',
  },
  production: {
    site_api: 'https://api2.moneytapcard.com',
  },
  imgUrl: 'https://api2.moneytapcard.com/uploads',
};
